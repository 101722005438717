var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box result_box", class: _vm.isMobile ? "m" : "pc" },
    [
      _c("div", { staticClass: "pay_logo" }, [
        _vm.payLogo
          ? _c("img", { attrs: { src: _vm.payLogo, width: "60", alt: "" } })
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "pay_result_info flex-start",
          class: { green: _vm.isSuccess, red: _vm.isFail || _vm.isLoading },
        },
        [
          _c(
            "div",
            [
              _vm.isLoading
                ? _c("span", { staticClass: "el-icon-loading" })
                : _c("el-result", {
                    attrs: { icon: _vm.isSuccess ? "success" : "error" },
                  }),
            ],
            1
          ),
          _c("div", [
            _c("p", { staticClass: "code" }, [_vm._v(_vm._s(_vm.orderCode))]),
            _c("p", [
              _vm.isSuccess
                ? _c("span", [_vm._v(_vm._s(_vm.$sys("warn.paySuccessMsg")))])
                : _vm.isFail
                ? _c("span", [_vm._v(_vm._s(_vm.failMsg || "Fail"))])
                : _c("span", [_vm._v(_vm._s(_vm.$sys("warn.payLoadingMsg")))]),
              _vm._v(" （" + _vm._s(_vm.second) + "s） "),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }