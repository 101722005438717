<template>
    <div class="box result_box" :class="isMobile ? 'm': 'pc'">
        <div class="pay_logo">
            <img v-if="payLogo" :src="payLogo" width="60" alt="">
        </div>

        <div class="pay_result_info flex-start" :class="{ 'green': isSuccess, 'red': isFail || isLoading }">

            <div>

                <span class="el-icon-loading" v-if="isLoading"></span>

                <el-result v-else :icon="isSuccess ? 'success' : 'error'"></el-result>

            </div>

            <div>
                <p class="code">{{ orderCode }}</p>
                <p>
                    <span v-if="isSuccess">{{ $sys('warn.paySuccessMsg') }}</span>

                    <span v-else-if="isFail">{{ failMsg || 'Fail' }}</span>

                    <span v-else>{{ $sys('warn.payLoadingMsg') }}</span>

                    （{{ second }}s）
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Result } from 'element-ui'
import { mapGetters } from 'vuex'
import { setPayResult, removePayResult, getPayInfo, removePayInfo } from '@/utils/storage'
import { getPayIconByName } from '@/utils/pay'
import { apiOrderResult } from '@/api/mall'
import { logPurchaseSuccess, logPurchaseFail } from '@/utils/log'

export default {
    components: {
        [Result.name]: Result
    },
    data() {
        const { payType, paySubType, paySuccess, payMessage, orderCode } = this.$route.query
        const isLoading = !paySuccess

        return {
            second: isLoading ? 10 : 3,
            payLogo: getPayIconByName(paySubType?.toLowerCase()) || getPayIconByName(payType?.toLowerCase()),
            isSuccess: paySuccess === 'true',
            isFail: paySuccess === 'false',
            failMsg: payMessage,
            isLoading,
            orderCode
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    },
    created() {        
        if(this.isSuccess) {
            setPayResult(1)
        }
        if(this.isFail) {
            setPayResult(0)
            
            logPurchaseFail(this.failMsg)
        }

        const timer = setInterval(() => {
            if (this.second === 0) {
                removePayResult()
                clearInterval(timer)
                this.$router.push('index')
                return
            }else {
                if(this.isLoading) {
                    if(this.second < 8) {
                        apiOrderResult(this.orderCode).then(res => {
                            if (res.code === 0) {
                                if(res.data?.paySuccess) {
                                    setPayResult(1)
                                    
                                    this.second = 3
                                    this.isLoading = false
                                    this.isSuccess = true

                                    if(getPayInfo()) {
                                        logPurchaseSuccess(getPayInfo())
                                        removePayInfo()
                                    }
                                }
                                if(res.data?.paySuccess === false) {
                                    setPayResult(0)

                                    this.second = 3
                                    this.isLoading = false
                                    this.isFail = true

                                    logPurchaseFail('other')
                                }
                            }
                        })
                    }
                }
            }
            this.second--
        }, 1000)
    }
}
</script>

<style lang="scss" scoped>
.pay_logo {
    margin: 10px 0;
}
.result_box {
    margin: 20px auto;
    background-color: #fff;
    &.pc {
        width: 1200px;
        padding: 20px 20px 50px;
        border-radius: 10px;
    }
    &.m {
        padding: 10px 10px 20px;
    }
    .el-result {
        padding: 20px;
    }
}
.pay_result_info {
    border: 1px solid #ccc;
    &.green {
        background-color: #EDFFCC;
        border-color: #b8d189
    }
    &.red {
        background-color: #FCEEE8;
        border-color: #f7b79c
    }
    .code {
        font-size: 14px;
    }
}
.el-icon-loading {
    font-size: 30px;
    padding: 20px;
}
</style>